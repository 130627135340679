/* global $, $$ */ /* Bliss */

import { SwipeDetector } from "./swipedetector"

function clamp(num, min, max) {
  // Return num clamped to [min, max]
  return Math.max(min, Math.min(max, num))
}

function replaceHash(hash) {
  if (window.history && window.history.replaceState) {
    window.history.replaceState({ hash }, "", `./#${hash}`)
  }
}

let slideArr = []
// let bgSizeArr = []
// let bgPositionArr = []
let slideArrBottom = []

export class Slider {
  constructor(element) {
    this.element = element
    this.slides = Array.from(element.childNodes).filter((e) => {
      return e.nodeType === 1 && e.className.indexOf("slide") !== -1
    })
    this.slideCount = this.slides.length
    this.position = null

    let hash = window.location.hash.replace(/^#/, "")

    for (let idx = 0; idx < this.slides.length; ++idx) {
      if (this.slides[idx].id === hash) {
        this.slideTo(idx)
        break
      }
    }
    if (this.position === null) this.slideTo(0)

    /* Because of MS Edge's inability to transition to calc()ed values,
     * attach a window resize handler :-( */
    window.addEventListener("resize", this._updateTraktorPosition.bind(this))

    /* hacky hack */
    $.delegate($(".slides"), "click", "a.go-right, a.go-left", (e) => {
      if (e.target.classList.contains("go-left")) {
        this.slideHorizontallyBy(-1)
      } else if (e.target.classList.contains("go-right")) {
        this.slideHorizontallyBy(1)
      } else return

      e.preventDefault()
    })
  }

  slideTo(newPosition) {
    let cl = document.body.classList
    if (cl.contains("wall-open") && !cl.contains("footer-open")) return

    this.position = clamp(newPosition, 0, this.slides.length - 1)

    this._applyTransform(
      this.element,
      `translate3d(0, -${this.position * 100}vh, 0)`,
    )

    this._updateTraktorPosition()

    document.body.className = this.slides[this.position].dataset.bodyClass || ""
    replaceHash(this.slides[this.position].id)

    this.subslider = $(".slides", this.slides[this.position])
    this.subsliderSlideCount = $$(".slide", this.subslider).length
    this.subsliderPosition = 0
    this._applySubsliderPosition()
    this.element.dispatchEvent(new Event("sliderchange"))
    // let imageWall = document.querySelector('.wall')
    let image = document.querySelector('image[data-attribute="middle"]')
    let imageTop = document.querySelector(".wall .top")
    let imageTopPrev = document.querySelector(".wall .top-prev")
    let imageBottomPrev = document.querySelector(".wall .bottom-prev")
    let imageBottom = document.querySelector(".wall .bottom")
    let backgroundImages = $$(".bg-images")
    let backgroundImagesBottom = $$(".bg-images__bottom")
    // media Query
    let mediaQuery = window.matchMedia("(max-width: 420px)")
    // prev Values
    // let prevBackgroundSize = imageTop.style.backgroundSize
    // let prevBackgroundPosition = imageTop.style.backgroundPosition

    let defaultBottom = document
      .querySelector(".bottom")
      .getAttribute("default")
    let defaultTop = document.querySelector(".top").getAttribute("default")
    let defaultMiddle = document
      .querySelector(".top")
      .getAttribute("default-middle")
    image.setAttribute("data-position", this.position)
    imageTop.setAttribute("data-position", this.position)
    imageBottom.setAttribute("data-position", this.position)
    let key = this.slides[this.position].getAttribute("key")
    document.querySelector(".wall").setAttribute("id", key)
    image.setAttribute("data-key", key)
    imageTop.setAttribute("data-key", key)
    //imageTop.classList.add('fade')
    imageBottom.setAttribute("data-key", key)

    if (!imageTop.classList.contains("fade")) {
      imageTop.classList.add("fade")
      imageBottom.classList.add("fade")
      setTimeout(() => {
        imageTop.classList.remove("fade")
        imageBottom.classList.remove("fade")
      }, 600)
    }

    let state = []
    //bgSizeArr.push(prevBackgroundSize)
    //  bgPositionArr.push(prevBackgroundPosition)
    slideArr.push(imageTop.getAttribute("current-src"))
    slideArrBottom.push(imageBottom.getAttribute("current-src"))
    if (backgroundImages) {
      backgroundImages.forEach((e, value) => {
        e.setAttribute("data-position", value)
        if (e.getAttribute("data-key") === image.getAttribute("data-key")) {
          state.push("true")
          image.setAttribute("xlink:href", e.getAttribute("src"))
          image.setAttribute("id", `data-${key}`)

          if (mediaQuery.matches) {
            imageTop.setAttribute(
              "style",
              `background-image: url('${e.getAttribute("data-mobile")}')`,
            )
            imageTop.setAttribute("current-src", e.getAttribute("data-mobile"))
          } else {
            imageTop.setAttribute(
              "style",
              `background-image: url('${e.getAttribute("src")}')`,
            )
            imageTop.setAttribute("current-src", e.getAttribute("src"))
          }

          window.addEventListener("resize", () => {
            setTimeout(() => {
              if (mediaQuery.matches) {
                imageTop.setAttribute(
                  "style",
                  `background-image: url('${e.getAttribute("data-mobile")}')`,
                )
                imageTop.setAttribute(
                  "current-src",
                  e.getAttribute("data-mobile"),
                )
              } else {
                imageTop.setAttribute(
                  "style",
                  `background-image: url('${e.getAttribute("src")}')`,
                )
                imageTop.setAttribute("current-src", e.getAttribute("src"))
              }
            }, 200)
          })
        } else if (
          e.getAttribute("data-key") !== image.getAttribute("data-key")
        ) {
          state.push("false")
        }
        if (state.includes("false") && !state.includes("true")) {
          imageTop.setAttribute(
            "style",
            `background-image: url('${defaultTop}')`,
          )
          imageTopPrev.setAttribute(
            "style",
            `background-image: url('${defaultTop}')`,
          )
          image.setAttribute("xlink:href", defaultMiddle)
          imageTop.setAttribute("current-src", imageTop.getAttribute("default"))
        }
      })
      let insertedItem = slideArr.slice(-1)[0]
      // let insertPostion = bgPositionArr.slice(-1)[0]
      //  let insertSize = bgSizeArr.slice(-1)[0]
      imageTopPrev.setAttribute(
        "style",
        `background-image: url('${insertedItem}')`,
        // `background-position: ${insertPostion}`,
        // `background-size: ${insertSize}`
      )
    }
    if (backgroundImagesBottom) {
      backgroundImagesBottom.forEach((e) => {
        if (
          e.getAttribute("data-key") === imageBottom.getAttribute("data-key")
        ) {
          imageBottom.setAttribute(
            "style",
            `background: url('${e.getAttribute("src")}')  center top`,
          )
          imageBottom.setAttribute("current-src", e.getAttribute("src"))
        }
        if (state.includes("false") && !state.includes("true")) {
          imageBottom.setAttribute(
            "style",
            `background: url('${defaultBottom}')  center top;`,
          )
          imageBottomPrev.setAttribute(
            "style",
            `background: url('${defaultBottom}')  center top;`,
          )
          imageBottom.setAttribute(
            "current-src",
            imageBottom.getAttribute("default"),
          )
        }
      })
    }
    let insertedItemBottom = slideArrBottom.slice(-1)[0]

    imageBottomPrev.setAttribute(
      "style",
      `background: url('${insertedItemBottom}')  center top;`,
    )
  }

  _applySubsliderPosition() {
    if (!this.subslider) return

    this.subslider.style.width = `${this.subsliderSlideCount}00%`
    this.subsliderPosition = clamp(
      this.subsliderPosition || 0,
      0,
      $$(".slide", this.subslider).length - 1,
    )

    this._applyTransform(
      this.subslider,
      `translate3d(-${this.subsliderPosition * 100}vw, 0, 0)`,
    )

    let left = $(".go-left", this.subslider.parentNode),
      right = $(".go-right", this.subslider.parentNode)
    if (this.subsliderPosition !== 0) {
      left.classList.add("show")
    } else {
      left.classList.remove("show")
    }
    if (this.subsliderPosition !== this.subsliderSlideCount - 1) {
      right.classList.add("show")
    } else {
      right.classList.remove("show")
    }
  }

  slideVerticallyBy(delta) {
    this.slideTo(this.position + delta)
  }

  slideHorizontallyBy(delta) {
    this.subsliderPosition += delta
    this._applySubsliderPosition()
  }

  _applyTransform(element, transform) {
    element.style.webkitTransform =
      element.style.MozTransform =
      element.style.msTransform =
      element.style.OTransform =
      element.style.transform =
        transform
  }

  _updateTraktorPosition() {
    let $traktor = $(".traktor")
    if (!$traktor) {
      return
    }

    let positions = 10,
      halfTraktor = 75,
      current = this.position

    /* MS Edge is unable to animate calc() */
    let traktorCenter =
      (window.innerWidth / positions) *
      (0.5 + clamp(current - 1, -1, positions))
    $traktor.style.left = `${traktorCenter - halfTraktor}px`
  }

  attachMouseWheelHandler() {
    let slider = this,
      scrollingWithWheelTime = null,
      mouseWheelHandler = function (e) {
        /*       $$('.c-ice_hemp_large').forEach(function(e) {
          if (e.classList.contains('fadein')) {
            e.classList.remove('fadein')
          } else {
            e.classList.add('fadein')
          }
        })*/

        // console.log(new Date(), e);
        let delta = -e.wheelDelta || e.deltaY || e.detail || 0,
          now = new Date().getTime()
        if (scrollingWithWheelTime && now - scrollingWithWheelTime < 750) {
          // Are we currently scrolling && last scroll event less than 750ms past?
          // Ignore!
          if (Math.abs(delta) < 1)
            // But stop ignoring, once the scroll delta goes to zero (OS X
            // trackpad continues sending events for some time)
            scrollingWithWheelTime = null
        } else if (
          !scrollingWithWheelTime ||
          now - scrollingWithWheelTime > 750
        ) {
          // Are we NOT scrolling now || last scroll event more than 750ms gone?
          slider.slideVerticallyBy(Math.sign(delta))
          scrollingWithWheelTime = new Date().getTime()
        }
      }

    this.element.addEventListener("wheel", mouseWheelHandler)
    this.element.addEventListener("mousewheel", mouseWheelHandler)
    this.element.addEventListener("onmousewheel", mouseWheelHandler)
  }

  attachArrowKeysHandler() {
    let slider = this,
      arrowKeysHandler = function (e) {
        switch (e.keyCode) {
          case 37: // left
            slider.slideHorizontallyBy(-1)
            break
          case 38: // up
            slider.slideVerticallyBy(-1)
            break
          case 39: // right
            slider.slideHorizontallyBy(1)
            break
          case 40: // down
            slider.slideVerticallyBy(1)
            break
          default:
            return
        }
        e.preventDefault()
      }

    this.element.setAttribute("tabindex", 1) // Make element able to receive key events
    this.element.addEventListener("keydown", arrowKeysHandler)
    this.element.focus()
  }

  attachSwipeDetector() {
    let detector = new SwipeDetector(this.element)

    detector.emitter.on("up", () => this.slideVerticallyBy(1))
    detector.emitter.on("down", () => this.slideVerticallyBy(-1))

    detector.emitter.on("left", () => this.slideHorizontallyBy(1))
    detector.emitter.on("right", () => this.slideHorizontallyBy(-1))
  }
}
