/*eslint no-unused-vars: ["error", { "vars": "local" }]*/

import "blissfuljs/bliss.shy.js"
import "blissfuljs/bliss._.js"

const { $, $$ } = window // eslint-disable-line

import { Slider } from "./js/slider"
import Cookies from "js-cookie"

import debounce from "lodash/debounce"
// import throttle from 'lodash/throttle';
// import smoothScroll from 'smoothscroll';

$.ready().then(() => {
  $("[data-toggle-mobile-nav]").addEventListener("click", () => {
    $("[data-toggle-mobile-nav]").classList.toggle("white")
    document.body.classList.toggle("nav-is-open")
    $("#overlay").classList.toggle("open")
  })

  $$("[data-toggle-mobile-a]").forEach((e) => {
    e.addEventListener("click", () => {
      $("[data-toggle-mobile-nav]").classList.toggle("white")
      document.body.classList.toggle("nav-is-open")
      $("#overlay").classList.toggle("open")
    })
  })

  $$("[data-hover-bottle]").forEach((e) => {
    e.addEventListener("mouseover", () => {
      let bottleHeadline = e.getElementsByClassName("bottle-overview__title")
      e.classList.add("bottle-scale")
      bottleHeadline[0].classList.add("title-opacity")
    })
    e.addEventListener("mouseleave", () => {
      let bottleHeadline = e.getElementsByClassName("bottle-overview__title")
      e.classList.remove("bottle-scale")
      bottleHeadline[0].classList.remove("title-opacity")
    })
  })

  let productSlide = $("#products") || $("#smoothies")
  if (window.matchMedia("(max-width: 768px)").matches && productSlide) {
    productSlide.remove()
  }
  // window.addEventListener(
  //   'resize',
  //   debounce(function() {
  //     if (window.matchMedia('(max-width: 768px)').matches) {
  //       productSlide.classList.add('display-none')
  //     }
  //     if (window.matchMedia('(min-width: 769px)').matches) {
  //       productSlide.classList.remove('display-none')
  //     }
  //   }, 50)
  // )

  let slides = $(".slides.vertical"),
    slider = new Slider(slides)

  slider.attachMouseWheelHandler()
  slider.attachArrowKeysHandler()
  slider.attachSwipeDetector()

  // Splash screen
  // Control behaviour over data attributes on .splash element:
  // data-target-slide:
  //      Integer. If available, clicking on the image of the splash
  //      screen will fade out the slide and redirect the user to this
  //      slide.
  // data-timeout-days:
  //      Integer. If available, a cookie will be set and the the
  //      splash screen will not be shown for the defined number of
  //      days. Set to 0 or dont set attribute at all to show the
  //      splash screen every time the page is opened.
  let trigger = 1

  slides.addEventListener("sliderchange", () => {
    if (splash) {
      splash.classList.remove("splash--show")
    }
    if (slider.position == slider.slideCount - 1) {
      trigger = trigger + 1
      if (trigger == 3) {
        document.body.classList.add("footer-open")
      }
    } else {
      trigger = 1
    }
    document.body.classList.remove("nav-is-open")
    $("#overlay").classList.remove("open")
  })
  let splash = $(".splash")
  if (splash && !Cookies.get("popup-hide")) {
    splash.classList.add("splash--show")
    let splashCloseButton = $(".splash__close")
    splashCloseButton.addEventListener("click", () => {
      splash.classList.remove("splash--show")
      let timeoutDays = parseInt(splash.getAttribute("data-timeout-days"), 10)
      if (!isNaN(timeoutDays) && timeoutDays != 0) {
        Cookies.set("popup-hide", true, { expires: timeoutDays })
      }
    })

    let targetSlide = parseInt(splash.getAttribute("data-target-slide"), 10)

    let mediaQuery = window.matchMedia("(max-width: 768px)")

    if (mediaQuery.matches) {
      targetSlide = parseInt(
        splash.getAttribute("data-target-slide-mobile"),
        10,
      )
    }

    if (!isNaN(targetSlide)) {
      if ($(".splash__image")) {
        $(".splash__image").addEventListener("click", (e) => {
          if (!e.target.classList.contains("splash__close")) {
            slider.slideTo(targetSlide)
          }
        })
      }
    }
  }

  $("footer .lid").addEventListener("click", () => {
    if (document.body.classList.contains("start-screen")) {
      slider.slideTo(1)
    } else {
      document.body.classList.toggle("footer-open")
    }
  })

  slides.addEventListener("click", () =>
    document.body.classList.remove("footer-open"),
  )

  $(".gradient-bar").addEventListener("click", (e) => {
    let bottle = Math.ceil((e.clientX / window.innerWidth) * 6)
    slider.slideTo(bottle)
  })

  $$(".button").forEach((element) =>
    element.addEventListener("click", () => {
      if (document.body.classList.contains("wall-open")) {
        document.body.classList.remove("wall-open")
      } else {
        document.body.className = `wall-open ${element.dataset.smoothie}`
      }

      // Dumb hack to force a redraw (otherwise, SVG currentColor is not
      // applied, at least since we switched from fixed to absolute
      // positioning or something ???)
      let wall = $(".wall")
      wall.style.opacity = ".99"
      setTimeout(() => (wall.style.opacity = "1"), 0)
    }),
  )

  window.addEventListener("hashchange", (e) => {
    let hash = window.location.hash.replace(/^#/, "")
    if (hash == "products" || hash == "smoothies") {
      // Hardcoded -- no corresponding slide
      e.preventDefault()
      slider.slideTo(1)
    } else {
      // Find slide with matching ID
      $$(".slides.vertical > .slide").forEach((slide, idx) => {
        if (slide.id == hash) {
          e.preventDefault()
          slider.slideTo(idx)
        }
      })
    }
  })

  const repositionWall = function () {
    // iOS fix
    // function iOS() {
    //   let iDevices = ['iPhone Simulator', 'iPod Simulator', 'iPhone', 'iPod']
    //
    //   if (navigator.platform) {
    //     while (iDevices.length) {
    //       if (navigator.platform === iDevices.pop()) {
    //         return true
    //       }
    //     }
    //   }
    //
    //   return false
    // }
    //
    // // Android fix
    //
    // var ua = navigator.userAgent.toLowerCase();
    // var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    // if(isAndroid) {
    //   // Do something!
    //   // Redirect to Android-site?
    //   window.location = 'http://android.davidwalsh.name';
    // }

    let smoothieTextHeight = 350,
      offsetBottom = (window.innerHeight - smoothieTextHeight) / 2,
      arbitraryNumber = 270,
      minOffset = -90

    let offset = Math.max(minOffset, arbitraryNumber - offsetBottom)
    let mediaQuery = window.matchMedia("(max-width: 420px)")
    let mediaQueryVerySmall = window.matchMedia("(max-width: 320px)")
    if (mediaQuery.matches && !mediaQueryVerySmall.matches) {
      $(".wall").style.top = `${offset - 60}px`
    } else if (mediaQueryVerySmall.matches) {
      $(".wall").style.top = `${offset - 85}px`
    } else {
      $(".wall").style.top = `${offset}px`
    }
  }
  window.addEventListener("resize", debounce(repositionWall, 300))
  window.addEventListener("load", repositionWall)
  // repositionWall();  // initial.
})
